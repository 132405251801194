import { ReactNode, useState } from 'react';
import dynamic from 'next/dynamic';
import { useCombobox, UseComboboxReturnValue } from 'downshift';
import { matchSorter } from 'match-sorter';
import { Box, Button, ChakraProps, List, ListItem } from '@chakra-ui/react';

const Link = dynamic(() => import('./Link'));

export interface Option {
  id: number | string;
  name: string;
  node?: (searchTerm: string) => ReactNode;
  href?: string;
  value?: string;
  tags?: string[];
  target?: '_blank' | '_self';
}

export interface RenderProps
  extends Pick<
    UseComboboxReturnValue<Option>,
    'getInputProps' | 'getLabelProps' | 'openMenu'
  > {
  getOuterProps: UseComboboxReturnValue<Option>['getComboboxProps'];
  selectedItems: Option[];
  isSearching: boolean;
}

interface Props {
  items: Option[];
  initialItems?: Option[];
  children: (props: RenderProps) => Omit<ReactNode, 'string'>;
  onAdd?: (added: Option, values: Option[]) => void;
  onRemove?: (option: Option) => void;
  preventMultiselect?: boolean;
  selectedItems?: Option[];
  onBlur?: () => void;
  onGoTo?: (href: string) => void;
  onChange?: (value: string) => void;
  listVariant?: string;
}

export type { Props as AutoSuggestProps };

const itemToString = (item: Option) => item?.name || '';

const filterItemsToShow = (items: Option[], value: string) =>
  matchSorter(items, value, {
    keys: ['name', 'tags', 'office'],
    threshold: matchSorter.rankings.WORD_STARTS_WITH,
  });

const AutoSuggest = ({
  items = [],
  children,
  onAdd,
  onRemove,
  selectedItems,
  preventMultiselect = false,
  onBlur,
  onGoTo,
  onChange,
  initialItems,
  listVariant = 'searchResults',
  ...rest
}: Props & ChakraProps) => {
  const [val, setInputValue] = useState<string>('');

  const filteredItems =
    initialItems || val?.length > 1
      ? filterItemsToShow(
          !selectedItems
            ? items
            : items?.filter(
                (item) =>
                  !selectedItems.map(itemToString).includes(itemToString(item)),
              ),
          val,
        )
      : [];

  const {
    isOpen,
    openMenu,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    selectItem,
    getLabelProps,
  } = useCombobox<Option | null>({
    inputValue: val,
    items: filteredItems,
    onStateChange: ({ inputValue, type, selectedItem }) => {
      const value = inputValue || '';
      switch (type) {
        case useCombobox.stateChangeTypes.InputChange:
          setInputValue(value);

          if (onChange) {
            onChange(value);
          }
          break;
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputBlur:
          if (type === useCombobox.stateChangeTypes.InputBlur && onBlur) {
            onBlur();
          }
          if (selectedItem && onAdd) {
            setInputValue('');
            if (onChange) {
              onChange(value);
            }
            if (preventMultiselect && onRemove) {
              if (selectedItems?.length) {
                onRemove(selectedItems[0]);
              }
            }
            // NOTE: onAdd works as a onToggleCallback in case preventMultiselect. Which doesn't work with links, because apparently the click doesnt get through or something :facepalm:

            onAdd(selectedItem, selectedItems || []);
            selectItem(null);
          } else if (
            selectedItem?.href &&
            type === useCombobox.stateChangeTypes.InputKeyDownEnter &&
            onGoTo
          ) {
            onGoTo(selectedItem.href);
          }

          break;
        default:
          break;
      }
    },
    itemToString,
  });

  return (
    <Box pos="relative" {...rest}>
      {children({
        getInputProps,
        getOuterProps: () => getComboboxProps(),
        selectedItems: selectedItems || [],
        openMenu,
        isSearching: !!val,
        getLabelProps,
      })}
      <List
        borderRadius="md"
        pos="absolute"
        left="0"
        right="0"
        mt="2"
        bg="backgroundColor"
        color="primaryColor"
        variant={listVariant}
        zIndex="25"
        maxHeight="40vh"
        overflowY="auto"
        overflowX="hidden"
        aria-live="polite"
        {...getMenuProps()}
      >
        {isOpen &&
          filteredItems?.map(
            ({ id, href, target, node, name }: Option, index, arr) => {
              const { onClick, ...itemProps } = getItemProps({
                index,
                item: { name, id },
              });

              return (
                <ListItem
                  key={`${id}-${name}`}
                  borderTopRadius={!index ? 'md' : ''}
                  borderBottomRadius={arr.length - 1 === index ? 'md' : ''}
                  bg={highlightedIndex === index ? 'gray2' : 'transparent'}
                  onClick={onClick}
                  {...itemProps}
                >
                  {href ? (
                    <Link
                      href={href}
                      {...(target ? { target } : null)}
                      onClick={onClick}
                    >
                      {name}
                    </Link>
                  ) : (
                    <Button
                      variant="transparent"
                      type="button"
                      maxWidth="none"
                      width="100%"
                      onClick={onClick}
                    >
                      {node ? node(val) : name}
                    </Button>
                  )}
                </ListItem>
              );
            },
          )}
      </List>
    </Box>
  );
};

export default AutoSuggest;
