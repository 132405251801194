import { useRouter } from 'next/router';
import { Input, InputGroup, InputLeftElement } from '@chakra-ui/react';
import { MapPin } from '@phosphor-icons/react';
import { useAppdata, useTranslation } from '../lib/hooks.context';
import { Site } from '../types';
import AutoSuggest, { Option } from './AutoSuggest';

const siteToOption = (
  { path, blog_id: id, blogname: name, tags }: Site,
  page = '',
): Option => ({
  href: `${path.replace(/\/$/, '')}/${page}` || `/${page}`,
  id,
  name,
  tags,
});

const mapAssociationsToOptions = (sites: Site[], page = '') =>
  sites?.reduce(
    (acc, site) =>
      site?.path !== '/' ? [...acc, siteToOption(site, page)] : acc,
    [],
  );

const AssociationSearch = ({ page = '' }) => {
  const { sites } = useAppdata();
  const { push } = useRouter();
  const t = useTranslation();

  const onGoToBySelect = (href: string) => {
    push(href);
  };

  return (
    <AutoSuggest
      items={mapAssociationsToOptions(sites, page)}
      onGoTo={onGoToBySelect}
    >
      {({ getInputProps, getOuterProps }) => (
        <InputGroup
          {...getOuterProps()}
          display="inline-flex"
          width="auto"
          size="xl"
        >
          <InputLeftElement pointerEvents="none" color="highlightColor">
            <MapPin size="18" />
          </InputLeftElement>
          <Input
            variant="search"
            pr="8"
            pl="10"
            border="0"
            {...getInputProps({
              id: 'associations-search',
              placeholder: t('search_association_placeholder'),
            })}
          />
        </InputGroup>
      )}
    </AutoSuggest>
  );
};

export default AssociationSearch;
